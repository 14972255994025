var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.treeLoading,
          expression: "treeLoading",
        },
      ],
    },
    [
      _c("el-input", {
        staticStyle: { "margin-bottom": "10px" },
        attrs: {
          placeholder: _vm.$t("lang_enter_keywords_to_filter"),
          "suffix-icon": "el-icon-search",
          clearable: "",
        },
        on: { change: _vm.filterNodeData },
        model: {
          value: _vm.filterText,
          callback: function ($$v) {
            _vm.filterText = $$v
          },
          expression: "filterText",
        },
      }),
      _c("el-tree", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.treeLoading,
            expression: "treeLoading",
          },
        ],
        ref: "treeRef",
        staticClass: "task-main-left-tree",
        attrs: {
          data: _vm.treeData,
          props: _vm.defaultProps,
          "filter-node-method": _vm.filterNode,
          "node-key": "id",
          "empty-text": _vm.$t("lang_no_data"),
          "highlight-current": "",
          "default-expand-all": "",
          "expand-on-click-node": false,
        },
        on: { "node-click": _vm.handleNodeClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }