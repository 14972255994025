





























import { Component, Ref, Vue } from 'vue-property-decorator';
import AppTypeApi from '@/api/application/AppTypeApi';

@Component({
  name: 'FlowApplicationTypeTree',
  components: {}
})
export default class FlowApplicationTypeTree extends Vue {
  // ref
  @Ref() readonly treeRef;

  // 树的loading
  treeLoading: boolean = false;
  // 过滤文本
  filterText: string = '';
  // 树的数据
  treeData: any[] = [];

  // 树节点props
  defaultProps: any = {
    children: 'applications',
    label: 'displayName'
  };

  created() {
    this.getTreeData();
  }

  /**
   * 搜索树节点
   */
  filterNodeData() {
    if (this.treeRef) {
      this.treeRef.filter(this.filterText);
    }
  }

  //获取树数据
  getTreeData() {
    this.treeLoading = true;
    (AppTypeApi as any)
      .tree()
      .then((res: any) => {
        if (res.code == '1') {
          this.treeData = res.data;
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 5000
          });
        }
      })
      .finally(() => {
        this.treeLoading = false;
      });
  }

  // 过滤树
  filterNode(value, data, node) {
    if (!value) return true;
    return data.displayName.indexOf(value) !== -1;
  }

  // 节点点击事件
  handleNodeClick(val) {
    this.$emit('node-click', val);
  }
}
